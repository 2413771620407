import useGetExperiences from "hooks/api/useGetExperiences";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import experiencesAtom from "store/experiences";

export default function useExperiencesStore () {
    const setExperiences = useSetRecoilState(experiencesAtom);
    const result = useGetExperiences();

    useEffect(() => {
        setExperiences(result);
    }, [result]);
}