import { useRoutes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routesAtom from "store/routes";
import { initializedWithStates } from "store/initialized/initializedWithStates";
import useRoutesStore from "hooks/store/useRoutesStore";
import useLocationStore from "hooks/store/useLocationStore";
import useExperiencesStore from "hooks/store/useExperiencesStore";

export function App () {
    useRoutesStore();
    useLocationStore();
    useExperiencesStore();

    const initialized = useRecoilValue(initializedWithStates);
    const routes = useRecoilValue(routesAtom);
    const element = useRoutes(routes ?? []);

    return (!initialized) ? (
        <></>
    ) : (
        <>{element}</> 
    );
}