import { useState, useEffect } from "react";
import PocketBase from "pocketbase";
import appConfig from "constants/appConfig";

const pb = new PocketBase(appConfig.apiBase);

export default function useGetExperiences () {
    const [experiences, setExperiences] = useState();

    useEffect(() => {
        (async () => {
            try {
                const response = await pb.collection("experiences").getFullList();
                
                if (response) {
                    setExperiences(response);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    return experiences;
}