import { lazy, Suspense } from "react";
import sharedRoutes from "./sharedRoutes";

const HomePage = lazy(() => import("pages/HomePage"));
const AboutPage = lazy(() => import("pages/AboutPage"));
const ExperiencesPage = lazy(() => import("pages/ExperiencesPage"));
const ExperienceDetailPage = lazy(() => import("pages/ExperienceDetailPage"));
const ContactPage = lazy(() => import("pages/ContactPage"));
const ThankYouPage = lazy(() => import("pages/ThankYouPage"));

const privateRoutes = [
    {
        key: "home",
        path: "/",
        element: <Suspense fallback={<></>}><HomePage /></Suspense>,
        children: []
    },
    {
        key: "about",
        path: "/about",
        element: <Suspense fallback={<></>}><AboutPage /></Suspense>,
        children: []
    },
    {
        key: "experiences",
        path: "/experiences",
        element: <Suspense fallback={<></>}><ExperiencesPage /></Suspense>,
        children: [
            
        ]
    },
    {
        key: "experienceDetail",
        path: "/experiences/:experienceId",
        element: <Suspense fallback={<></>}><ExperienceDetailPage /></Suspense>,
        children: []
    },
    {
        key: "contact",
        path: "/contact",
        element: <Suspense fallback={<></>}><ContactPage /></Suspense>,
        children: [
            
        ]
    },
    {
        key: "contact-thankyou",
        path: "/contact/thanks",
        element: <Suspense fallback={<></>}><ThankYouPage /></Suspense>,
        children: []
    },
    ...sharedRoutes
];

export default privateRoutes;