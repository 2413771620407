import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "parts/App";
import "./assets/css/variables.scss";
import "./assets/css/base.scss";
import storageKeys from 'constants/storageKeys';
import { ThpaceGL } from 'thpace';

(() => {

    // theming
    const body = document.querySelector("body");
    
    const storageTheme = localStorage.getItem(storageKeys.theme);
    let currentTheme;

    if (!storageTheme || storageTheme.includes("dark")) {
        currentTheme = "dark";
    } else if (storageTheme.includes("light")) {
        currentTheme = "light";
    } else {
        currentTheme = "dark";
    }

    body.classList.add(`theme-${currentTheme}`);
    body.dataset.siteTheme = currentTheme;

})();




const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
    <StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>
    </StrictMode>
);
