
const env = process.env;

const appName = "Portfolio 2024";
const appShortName = "prtfl2024";

const appConfig = {
    name: appName,
    shortName: appShortName,
    version: env.REACT_APP_VERSION,
    cacheName: `${appShortName}cache_v${env.REACT_APP_VERSION}`,
    apiBase: env.REACT_APP_API_BASE 
};

export default appConfig;