import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import publicRoutes from "constants/routes/publicRoutes";
import routesAtom from "store/routes";

export default function useRoutesStore () {
    const setRoutes = useSetRecoilState(routesAtom);

    useEffect(() => {
        setRoutes(publicRoutes);
    }, [setRoutes]);
}